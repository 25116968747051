<template>
  <div>
    <router-link
      :to="{ name: 'PropertyVisitTimeSlots' }"
      class="link tg-mobile-link-small"
    >
      <i class="far fa-chevron-left mr-2" />
      {{ $t('choose_visit_time') }}
    </router-link>
    <h3 class="my-5 tg-mobile-header-3 lg:tg-desktop-header-3">
      {{ $t('your_visit_moment') }}
    </h3>
    <p class="my-5 tg-mobile-body lg:tg-desktop-body">
      {{ $t('visit_enter_details_message') }}
    </p>
    <FormulateForm
      #default="{ isLoading, hasErrors }"
      v-model="candidate"
      name="propertyBidDetails"
      @submit="bookVisit"
    >
      <FormulateInput
        type="text"
        name="first_name"
        :label="$t('first_name')"
        validation="required:trim"
        :show-required-label="true"
      />
      <FormulateInput
        type="text"
        name="last_name"
        :label="$t('last_name')"
        validation="required:trim"
        :show-required-label="true"
      />
      <FormulateInput
        type="select"
        name="language"
        :label="$t('preferred_language')"
        :placeholder="$t('select_language')"
        :value="$i18n.locale"
        :options="langOptions"
        :show-required-label="true"
        validation="required"
      />
      <FormulateInput
        v-model.trim="candidate.email"
        type="email"
        name="email"
        :label="$t('email_label')"
        validation="bail|required|email"
        :show-required-label="true"
      />
      <FormulateInput
        type="dial_code_tel"
        name="phone_number_mobile"
        :label="$t('mobile')"
        :show-required-label="true"
        validation="bail|required|phone"
      />
      <p>
        {{ $t('gdpr_1') }}
        <a
          :href="$t('privacy_policy_property_link')"
          target="_blank"
          class="link underline inline-block"
        >
          {{ $t('privacy_policy') }}
        </a>
        <span>. {{ $t('gdpr_2') }}</span>
        <a
          :href="$t('privacy_policy_property_link')"
          target="_blank"
          class="link underline inline-block"
        >
          {{ $t('privacy_policy') }}
        </a>
        {{ $t('gdpr_3') }}
      </p>
      <div class="my-7.5">
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="hasErrors || isLoading"
          :input-class="['w-full lg:w-auto']"
          outer-class="my-0"
        >
          <i
            :class="[
              'far mr-2',
              isLoading ? 'fa-spinner-third animate-spin' : 'fa-check'
            ]"
          />
          {{ $t('confirm_appointment') }}
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { createPropertyVisitBooking } from '@/services/calendarService';
import { parsePhone, langOptions } from '@/helpers';

export default {
  name: 'PropertyVisitEnterDetails',
  data() {
    return {
      langOptions,
      candidate: {
        langOptions,
        language: this.$route.params.locale
      }
    };
  },
  methods: {
    bookVisit(data) {
      const { propertyId, slotId } = this.$route.params;
      let candidate = { ...data };
      candidate.phone_number_mobile = parsePhone(candidate.phone_number_mobile);

      return createPropertyVisitBooking(propertyId, slotId, { candidate })
        .then(response => {
          this.$store.dispatch('snackbar/show', {
            type: 'success',
            messageBold: response.data.candidate.first_name,
            message: ', ' + this.$t('visit_booked'),
            route: {
              name: 'PropertyVisitSummary',
              params: { summary: response.data }
            }
          });
        })
        .catch(error => {
          this.$formulate.handle(error, 'propertyBidDetails');
        });
    }
  }
};
</script>
